<template>
  <div class="home">
    <app-carousel-slider></app-carousel-slider>
    <section class="rec text-center">
      <h3 class="mt-0 text-uppercase" :class="{ recommendText: !userMemberhipType }">
        {{ translations.tcWeRecommend }}
      </h3>
      <div class="wrap">
        <div class="container">
          <div class="row">
            <div class="col" v-if="userMemberhipType">
              <router-link to="/resources/donations" class="font-weight-bold">
                <img src="../assets/svgs/giving.svg" alt="Giving" height="78" width="78" />
                <div>{{ translations.tcMyCamp }}</div>
              </router-link>
            </div>
            <div class="col" v-if="userMemberhipType && isStoreOpen">
              <router-link to="/resources/store" class="font-weight-bold">
                <img src="../assets/cart.png" alt="Store" height="76" width="78" />
                <div>{{ translations.tcStore }}</div>
              </router-link>
            </div>
            <div class="col">
              <router-link to="/resources/media-portal" class="font-weight-bold">
                <img src="../assets/media.png" alt="Media & Resources" height="81" width="81" />
                <div>{{ translations.tcMediaResources }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- / .rec -->

    <section class="connect">
      <h2 class="mb-0">{{ translations.tcGetConnected }}</h2>
    </section>
    <!-- / .connect -->

    <section class="events">
      <div class="container">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </template>
          <template>
            <h2>{{ translations.tcUpcomingEvents }}</h2>
            <div class="row">
              <div v-if="events.length === 0" class="noevents">
                {{ translations.tcNoScheduledEventsFound }}
              </div>
              <div class="col-4" v-for="(event, ix) in editedEvents" :key="ix">
                <app-event :obj="event" :orgkey="user_camp_key" :i18n="translations.components"></app-event>
              </div>
            </div>
            <b-button to="/calendar/calendar-month" variant="secondary">
              {{ translations.tcGetConnected }}
            </b-button>
          </template>
        </b-skeleton-wrapper>
      </div>
    </section>
    <!-- / .events -->

    <section class="stats lazyload" id="stat">
      <div class="container" v-if="statsAreGood">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </template>
          <div class="row">
            <div class="col" v-for="(stat, index) in stats" :key="index">
              <app-graph :stat="stat"></app-graph>
            </div>
          </div>
          <b-button variant="primary" to="/officers/camp/camp-dashboard/#cdash" class="more">
            {{ translations.tcViewMore }}
          </b-button>
        </b-skeleton-wrapper>
      </div>
    </section>
    <!-- / .stats -->

    <section class="church-life container">
      <div class="row card-deck text-center">
        <div class="card col border-bot">
          <img src="../assets/church.png" alt="Church" height="64" width="64" />
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <div class="icon"></div>
              <h5 class="card-title">{{ translations.tcChurchMembership }}</h5>
              <p class="card-text text-left" v-if="!!demograph.church_name">
                {{ translations.tcYouAreAMemberOf }}
                <strong>{{ demograph.church_name }}</strong>.
                <span v-if="!!demograph.last_visit">
                  {{ translations.tcLastPresentationWas }}
                  <strong>{{ demograph.last_visit }}</strong>.
                </span>
                <span v-if="!demograph.last_visit">{{ translations.tcNoRecordOfPresentation }}</span>&nbsp;
                <span v-if="demograph.next_visit !== null">
                  {{ translations.tcNextPresentationScheduledFor }}
                  <strong>{{ demograph.next_visit }}</strong>.
                </span>
                <span v-if="demograph.next_visit === null">{{ translations.tcNoFuturePresentationsScheduled }}</span>
              </p>
              <p class="card-text text-left" v-if="!demograph.church_name">
                {{ translations.tcMemberShipDataNotAvailable }}
              </p>
              <b-button href="#" @click.prevent="handleMemberInfoClick" variant="primary">
                {{ translations.tcEdit }}
              </b-button>
            </b-skeleton-wrapper>
          </div>
        </div>
        <div class="card col border-bot">
          <img src="../assets/prayer.png" alt="#" height="77" width="47" />
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <div class="icon"></div>
              <h5 class="card-title">{{ translations.tcPrayerRequests }}</h5>
              <p class="car-text text-left words">
                <textra :data="words" :timer="10" :infinite="true" filter="left-right" />
              </p>
              <b-button to="/programs/pw/prayer-center-daily" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </section>
    <!-- / .cards -->

    <section class="announcements">
      <div class="container">
        <div class="row">
          <div class="card col">
            <b-skeleton-wrapper :loading="announcementLoading">
              <template #loading>
                <h3 class="text-uppercase">{{ translations.tcAnnouncements }}</h3>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </template>
              <div v-if="!!topAnnouncement">
                <h3 class="text-uppercase">{{ translations.tcAnnouncements }}</h3>
                <p class="car-text text-left words">
                  <textra :data="announcement_words" :timer="10" :infinite="true" filter="left-right" />
                </p>
                <b-button to="/get-connected/stay-informed/announcements" variant="secondary">
                  {{ translations.tcSeeMore }}
                </b-button>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </section>
    <!-- / .announcements -->

    <section class="customer-feedback">
      <div class="container">
        <div class="row">
          <div class="card border-bot col">
            <svg-stories :lang="prefCulture" :displayText="translations.tcSuccessStories"></svg-stories>
            <blockquote class="line">{{ successStory.description }}</blockquote>
            <div class="row btn-row">
              <div class="col">
                <b-button variant="primary" to="/get-connected/stay-informed/success-stories" class="more">
                  {{ translations.tcSeeAll }}
                </b-button>
              </div>
              <div class="col">
                <b-button variant="secondary" :to="{ name: 'submit-success-story' }" class="more">
                  {{ translations.tcSubmit }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="card border-bot col">
            <svg-testimonies :lang="prefCulture" :displayText="translations.tcTestimonies"></svg-testimonies>
            <blockquote class="quote">{{ testimony.description }}</blockquote>
            <div class="row btn-row">
              <div class="col">
                <b-button variant="primary" to="/get-connected/stay-informed/testimonies" class="more">
                  {{ translations.tcSeeAll }}
                </b-button>
              </div>
              <div class="col">
                <b-button variant="secondary" :to="{ name: 'submit-testimony' }" class="more">
                  {{ translations.tcSubmit }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- / .customer-feedback -->
    <MemberPopup @setClearMessage="handleMemberPopOk($event)" :i18n="translations.components"
      :pop_key="memberPopup.pop_key" :htmlMessage="memberPopup.pop_message" :modal_title="memberPopup.pop_title">
    </MemberPopup>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import { memberMessagePopupMixin } from '@/mixins/memberMessagePopupMixin'
import { translationMixin } from '@/mixins/translationMixin'
import breadcrumbService from '@/services/breadcrumbService'

export default {
  name: 'home',
  mixins: [translationMixin, memberMessagePopupMixin],
  data() {
    return {
      announcementLoading: true,
      editedEvents: [],
      i18n: { event: {} },
      lang: 'en',
      life: {
        src1: '../assets/church.png',
        src2: '../assets/prayer.png',
      },
      loading: false,
      translations: {},
    }
  },
  async created() {
    if (!this.authenticated) {
      await this.$router.push('/login')
    } else {
      this.page_load(false)
    }
  },
  methods: {
    ...mapActions({
      clearAnnouncementsStore: 'announcements/clearAnnouncementsStore',
      getAnnouncementMessages: 'announcements/getAnnouncementMessages',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadMemberPopup: 'membership/loadMemberPopup',
      removeMemberPopup: 'membership/removeMemberPopup',
      setHomeBannerData: 'home/setHomeBannerWidgetData',
      setHomePageWidgetData: 'home/setHomePageWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberPopupShown: 'user/setMemberPopupShown',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    scrollToElement(options) {
      const el = this.$el.getElementsByClassName('events')[0]
      if (el) {
        el.scrollIntoView(options)
      }
    },

    async page_load(skipAnnouncements) {
      try {
        this.setLoadingStatus(true)
        this.loading = true
        await breadcrumbService.loadBreadcrumTranslations()
        await this.setHomeBannerData()
        await Promise.all([

          this.getViewTranslations(),
          this.setHomePageWidgetData({
            ind_key: this.userId,
            org_key: this.user_camp_key,
            lng_key: this.userLanguageKey,
          }),
          (this.i18n = await this.getComponentTranslations('event', 'freshdesk-widget', 'member-popup')),
        ]).then((results) => {
          const translatedText = { ...results[2] }
          this.$set(this.translations, 'components', translatedText)

          this.loading = false
          if (!this.memberPopupShown) {
            this.loadPopup()
          }
          // avoid duplicate api call
          if (!skipAnnouncements) {
            this.getAnnouncementMessages()
          }
          this.editEvents()
        })

      } catch (e) {
        console.error('Error in Home.vue, page_load(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    editEvents() {
      if (!!this.events) {
        this.editedEvents = this.events.map((e) => {
          var fullDate = e.mtg_start_date
          return { ...e, fullDate: fullDate }
        })
      }
    },

    async handleMemberInfoClick() {
      this.setSelectedIndividualKey(this.userId)
      this.loadIndividualProfile(),
        this.$router.push({
          name: 'individual-details-edit',
        })
    },
    charLimit(text, limit) {
      return !!text ? (text.length > limit ? text.substring(0, limit) + '...' : text) : ''
    },
    viewMore(nd) {
      this.$router.push(nd)
    },
    handleMemberPopOk(data) {
      if (data.dontShow === 'true') {
        const payload = { pop_key: data.pop_key, ind_key: this.userId }
        this.removeMemberPopup(payload)
      }
    },
  },
  mounted() {
    this.$root.$on('HomePageLoad', (data) => {
      this.page_load(true)
    })
  },
  computed: {
    ...mapGetters({
      announcementList: 'announcements/announcements',
      authenticated: 'user/authenticated',
      demograph: 'user/demograph',
      events: 'home/upcomingEvents',
      isStoreOpen: 'menu/isStoreOpen',
      memberPopup: 'membership/memberPopup',
      memberPopupShown: 'user/memberPopupShown',
      prayerRequest: 'home/prayerRequest',
      prefCulture: 'user/userPreferredCulture',
      stats: 'home/homeStats',
      successStory: 'home/successStory',
      testimony: 'home/testimony',
      topAnnouncement: 'announcements/topAnnouncement',
      user_camp_key: 'user/userCampKey',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userLogin: 'user/userLogin',
    }),
    statsAreGood() {
      return this.stats[0].data.current > -1 && this.stats[1].data.current > -1 && this.stats[2].data.current > -1
    },
    userMemberhipType() {
      return this.userLogin.campnumber[0] === 'U'
    },
    words() {
      return !!this.prayerRequest && this.prayerRequest.length > 0
        ? this.prayerRequest.map((pr) => {
          return `<p>${pr.title} (${pr.category})</p>${pr.request}`
        })
        : [this.translations.tcNoCurrentPrayerRequests]
    },
    announcement_words() {
      return !!this.announcementList && this.announcementList.length > 0
        ? this.announcementList.map((al) => `<p>${al.msg_title}</p>${al.msg_message}`)
        : [this.translations.tcNoCurrentAnnouncements]
    },
  },
  watch: {
    topAnnouncement(value) {
      if (value) {
        this.announcementLoading = false
      }
    },
  },
  components: {
    appCarouselSlider: () => import('@/components/CarouselSlider.vue'),
    appEvent: () => import('@/components/Event.vue'),
    appGraph: () => import('@/components/Graph.vue'),
    MemberPopup: () => import('@/components/MemberPopup.vue'),
    svgStories: () => import('@/assets/svgs/successStories.vue'),
    svgTestimonies: () => import('@/assets/svgs/testimonies.vue'),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.words {
  height: 180px;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 28px;
}

.noevents {
  color: #fff;
  margin-left: 17px;
}

.btn {
  padding: 12px 35px;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  text-transform: uppercase;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-secondary {
  background-color: #fbc301;
}

.btn.btn-primary {
  background-color: #003946;
}

.ann-text {
  color: #747474;
  font-family: 'Lora', serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 36px;
  margin-bottom: 42px;
}

.ann-title {
  font-size: 22px;
  font-weight: 500;
}

.home {
  background-color: #f5f5f5;
  @import '@/styles/views/home.scss';
}

.more {
  width: auto !important;
}

.recommendText {
  z-index: 10;
  padding: 5px;
}

h3.mt-0.text-uppercase {
  max-width: 255px;
  font-size: 40px;
}
</style>
